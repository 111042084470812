














import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import CryptoBills from '../../components/vendors/CryptoBills.vue';
@Component({
  components: {
    CryptoBills,
  },
})
export default class CryptoBillsClass extends BaseVue {
  public selectedTab = 0;
}
