<template>
  <div>
    <ba-dialog :show="dialog" v-on:show-dialog="dialog = true" btnIcon="add" btnTooltip="Create Bill" title="New Bill">
      <v-container fluid grid-list-xl>
        <v-layout v-if="contacts.length > 0" wrap align-center>
          <v-flex offset-xs1 xs6>
            <v-menu
              lazy
              :close-on-content-click="false"
              v-model="menu"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <!-- https://codepen.io/kzelda/pen/pWOLBp -->
              <v-text-field slot="activator" label="Due Date" v-model="date" readonly></v-text-field>
              <v-date-picker v-model="date" no-title scrollable actions> </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex offset-xs1 xs6>
            <v-autocomplete
              v-model="vendor"
              :items="contacts"
              label="Vendor"
              item-text="name"
              item-value="id"
              persistent-hint
              return-object
            />
          </v-flex>
          <v-flex offset-xs1 xs6>
            <v-autocomplete
              :items="filteredCategories"
              label="Category"
              v-model="category"
              item-text="name"
              item-value="id"
              persistent-hint
              return-object
            >
              <template slot="item" slot-scope="data">
                <template>
                  <v-list-tile-content
                    >{{ data.item.name }} <sub>{{ data.item.type }}</sub></v-list-tile-content
                  >
                </template>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex offset-xs1 xs6>
            <v-text-field label="Amount" v-model="amount" prefix="$"></v-text-field>
          </v-flex>
          <v-flex offset-xs1 xs11 class="text-xs-left">
            <v-btn :disabled="!canSave" @click="save" color="primary"> Save </v-btn>
          </v-flex>
        </v-layout>
        <v-alert v-else type="warning">{{ $t('_warnMustSetAddress') }}</v-alert>
      </v-container>
      <v-divider></v-divider>
    </ba-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import * as _ from 'underscore';

export default {
  components: {},
  props: ['contacts', 'categories'],
  data() {
    return {
      dialog: false,
      category: {},
      vendor: {},
      date: '',
      menu: false,
      amount: '',
    };
  },
  computed: {
    canSave() {
      return Number(this.amount) > 0 && this.date !== '' && this.vendor !== {} && this.category !== {};
    },
    filteredCategories() {
      return _.filter(this.categories, (m) => {
        if (m.type === 'Expense') {
          if (this.vendor.accountingConnectionId && m.accountingConnectionId !== this.vendor.accountingConnectionId) {
            return false;
          }
          return true;
        }
        return false;
      });
    },
  },
  methods: {
    closeDialog() {
      this.$emit('refresh');
      this.dialog = false;
    },
    save() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        bill: {
          amount: Number(this.amount),
          dueDate: this.date,
          vendorId: this.vendor.id,
          categoryId: this.category.id,
          currency: 'USD',
          accountingConnectionId: this.vendor.accountingConnectionId,
        },
      };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $bill: BillInput!) {
              createBill(orgId: $orgId, bill: $bill) {
                id
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(() => {
          this.$emit('refresh');
          this.$emit('show-snackbar', this.$t('_billCreated'), 'success');
        })
        .catch((err) => {
          console.error(err);
          this.$emit('show-snackbar', err.message, 'error');
        })
        .finally(() => {
          this.amount = 0;
          this.category = {};
          this.vendor = {};
          this.date = '';
          this.dialog = false;
        });
    },
  },
};
</script>
