<!-- https://front.id/en/articles/vuetify-achieve-multiple-filtering-data-table-component -->
<template>
  <v-layout row wrap id="crypto-bills">
    <v-flex xs12>
      <v-card class="pa-2">
        <v-layout>
          <v-flex xs2>
            <create-bill
              :contacts="contacts"
              :categories="categories"
              @show-snackbar="showSnackbar"
              @refresh="refresh"
              v-if="checkScope(scopeLiterals.CryptoBillsCreate)"
            />
          </v-flex>
          <v-flex xs8></v-flex>
          <v-flex xs2 class="text-xs-right">
            <pay-bills
              @show-snackbar="showSnackbar"
              @refresh="refresh"
              :selected="validSelected"
              v-if="checkScope(scopeLiterals.CryptoBillsUpdate)"
            ></pay-bills>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex xs12>
      <v-data-table
        :headers="headers"
        :items="bills"
        item-key="id"
        :loading="isLoading ? 'accent' : false"
        :search="filters"
        :custom-filter="customFilter"
        :pagination.sync="pagination"
        class="elevation-3"
        select-all
        :rows-per-page-items="[10, 50, 100, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]"
        v-model="selected"
        ref="txnTable"
      >
        <template slot="items" slot-scope="bill">
          <tr
            :active="bill.selected"
            v-bind:class="{
              'no-address':
                !hasAddresses(bill.item.vendor.addresses) || filteredWallets(bill.item.vendor.addresses).length === 0,
            }"
          >
            <td>
              <v-tooltip
                v-if="!hasAddresses(bill.item.vendor.addresses)"
                right
                :disabled="hasAddresses(bill.item.vendor.addresses)"
              >
                <template slot="activator">
                  <div>
                    <v-checkbox disabled primary hide-details />
                  </div>
                </template>
                <span>This bill cannot be paid until an <br />address is set for this vendor</span>
              </v-tooltip>
              <v-tooltip
                v-else-if="filteredWallets(bill.item.vendor.addresses).length === 0"
                right
                :disabled="filteredWallets(bill.item.vendor.addresses).length > 0"
              >
                <template slot="activator">
                  <div>
                    <v-checkbox disabled primary hide-details />
                  </div>
                </template>
                <span>No wallet can pay to this vendor addresses</span>
              </v-tooltip>
              <v-checkbox v-else primary hide-details v-model="bill.selected"></v-checkbox>
            </td>
            <td class="text-xs-center">
              {{ bill.item.dueDate }}
            </td>
            <td class="text-xs-center">
              {{ bill.item.category.name }}
            </td>
            <td class="text-xs-center">
              {{ bill.item.vendor.name }}
            </td>
            <td class="text-xs-center">
              {{ bill.item.displayAmount }}
            </td>
            <!--td class="text-xs-center">
              <v-btn :small="true" slot="activator" dark class="secondary"> Edit </v-btn>
            </td-->
          </tr>
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="10000" :top="true" multi-line vertical>
      {{ snackbarText }}
      <ul v-if="snackbarErrors.length">
        <li v-for="item in snackbarErrors" v-bind:key="item">{{ item }}</li>
      </ul>
      <v-btn flat @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment';

import { BillsQuery, CategoriesQuery, ContactsQuery } from '../../queries/transactionsPageQuery';
import CreateBill from './CreateBill';
import PayBills from './PayBills';

export default {
  props: ['appRefs'],
  components: { PayBills, CreateBill },
  data: () => ({
    pagination: {
      sortBy: 'created',
      descending: true,
    },
    snackbar: false,
    snackbarColor: 'success',
    snackbarText: '',
    snackbarErrors: [],
    defaultRecStatusFilter: 'All',
    defaultCatStatusFilter: 'All',
    filters: {
      search: '',
      categorizationStatus: 'All',
      reconciliationStatus: 'All',
    },
    authors: ['Admin', 'Editor'],
    categorizationStatuses: ['All', 'Uncategorized', 'Categorized'],
    reconciliationStatuses: ['All', 'Reconciled', 'Unreconciled'],
    headers: [
      {
        text: 'Due Date',
        align: 'center',
        value: 'dueDate',
      },
      {
        text: 'Category',
        align: 'center',
        value: 'category',
      },
      {
        text: 'Vendor',
        align: 'center',
        value: 'displayVendor',
      },
      {
        text: 'Amount',
        align: 'center',
        value: 'displayAmount',
      },
      // {
      //   text: '',
      //   value: '',
      // },
    ],
    isLoading: 0,
    selected: [],
    contacts: [],
    categories: [],
    wallets: [],
  }),
  apollo: {
    wallets: {
      query: gql`
        query wallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            type
            balance {
              totalFiatValue {
                displayValue
                currency
              }
            }
            enabledCoins
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      update(data) {
        return data.wallets;
      },
      loadingKey: 'isLoading',
    },
    bills: {
      query: BillsQuery,
      pollInterval: 20000,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
    categories: {
      query: CategoriesQuery,
      pollInterval: 20000,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
    contacts: {
      query: ContactsQuery,
      pollInterval: 20000,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    toggleAll() {
      const anySelected = this.transactions.txns.find((m) => m.selected);
      if (anySelected) {
        this.transactions.txns.forEach((m) => {
          this.$set(m, 'selected', false);
          // m.selected = false;
        });
      } else {
        this.transactions.txns.forEach((m) => {
          this.$set(m, 'selected', true);
        });
      }
    },
    refresh() {
      this.selected = [];
      this.$apollo.queries.bills.refetch();
    },
    cleanMeta(apolloObj) {
      return _.omit(apolloObj, '__typename');
    },
    hasAddresses(addresses) {
      const t = _.values(this.cleanMeta(addresses)).join('');
      return t.length > 0;
    },
    hasWalletForAddresses(addresses) {
      const t = _.values(this.cleanMeta(addresses)).join('');
      return t.length > 0;
    },
    filterEmptyAddresses(addresses) {
      const addr = {};
      for (const k in this.cleanMeta(addresses)) {
        if (addresses[k] && addresses[k].address && addresses[k].address.trim().length > 0) {
          addr[k] = addresses[k];
        }
      }
      return addr;
    },
    filteredWallets(vendorAddresses) {
      // only enterprise wallets (2) that support $coins
      const vendorSupported = _.values(this.filterEmptyAddresses(vendorAddresses));
      const vendorSupportedCoins = vendorSupported.map((v) => v.coin);
      return _.filter(this.wallets, (w) => {
        const walletSupportedCoins = _.values(this.cleanMeta(w.enabledCoins));
        const hasIntersect = _.intersection(walletSupportedCoins, vendorSupportedCoins).length > 0;
        // console.log(w.type, vendorSupportedCoins, walletSupportedCoins);
        return w.type === 2 && hasIntersect;
      });
    },
    customFilter(items, filters, filter, headers) {
      // Init the filter class.
      const cf = new this.$MultiFilters(items, filters, filter, headers);

      cf.registerFilter('reconciliationStatus', (status, items) => {
        if (!status || status === 'All' || status.trim() === '') return items;

        return items.filter((item) => {
          return item.reconciliationStatus.toLowerCase() === status.toLowerCase();
        }, status);
      });

      cf.registerFilter('categorizationStatus', (status, items) => {
        if (!status || status === 'All' || status.trim() === '') return items;

        return items.filter((item) => {
          return item.categorizationStatus.toLowerCase() === status.toLowerCase();
        }, status);
      });

      // Its time to run all created filters.
      // Will be executed in the order thay were defined.
      return cf.runFilters();
    },
    filterSearch(val) {
      this.filters = this.$MultiFilters.updateFilters(this.filters, {
        search: val,
      });
    },
    filterRecStatus(val) {
      this.filters = this.$MultiFilters.updateFilters(this.filters, {
        reconciliationStatus: val,
      });
    },
    filterCatStatus(val) {
      this.filters = this.$MultiFilters.updateFilters(this.filters, {
        categorizationStatus: val,
      });
    },
    showSnackbar(message, type) {
      this.snackbar = true;
      this.snackbarColor = type;
      this.snackbarText = message.replace('GraphQL error: ', '');
    },
  },
  computed: {
    selectedCount() {
      return this.selected.length;
      // if(this.selected.length && this.transactions.txns) {
      //   return this.transactions.txns.filter(m => m.selected).length;
      // } else {
      //   return 0;
      // }
    },
    validSelected() {
      return _.filter(this.selected, (item) => {
        const addr = item.vendor.addresses;
        return this.hasAddresses(addr) && this.filteredWallets(addr).length > 0;
      });
    },
  },
};
</script>

<style>
#crypto-bills .v-tooltip.v-tooltip--right > span {
  display: flex;
}
#crypto-bills .no-address {
  background-color: lightgoldenrodyellow;
}
</style>
