<template>
  <div>
    <ba-dialog
      :show="dialog"
      v-on:show-dialog="dialog = true"
      title="Pay Bill"
      :disable-button="!hasSelection"
      :btn-name="btnText"
    >
      <v-data-table :headers="headers" :items="selected" hide-actions>
        <template slot="items" slot-scope="bill">
          <td class="">{{ bill.item.vendor.name }}</td>
          <td class="text-xs-right">{{ bill.item.displayAmount }}</td>
          <td class="">
            <v-select
              :items="vendorAddressList(bill.item.vendor.addresses)"
              v-model="bill.item.selectedCoin"
              single-line
              label="currency..."
            >
            </v-select>
          </td>
          <td class="">
            <v-select
              :items="filteredWallets(bill.item.vendor.addresses)"
              v-model="bill.item.selectedWalletId"
              item-text="name"
              single-line
              item-value="id"
              label="wallet..."
            >
              <template slot="item" slot-scope="data">
                <v-list-tile-content
                  >{{ data.item.name }}
                  <sub
                    >{{ data.item.balance.totalFiatValue.displayValue }}
                    {{ data.item.balance.totalFiatValue.currency }}
                  </sub></v-list-tile-content
                >
              </template>
            </v-select>
          </td>
        </template>
        <template slot="footer">
          <td :colspan="headers.length" class="text-xs-center">
            <p class="ma-3">
              Exchange Rate: <b>${{ price }}</b>
            </p>
            <v-flex xs2 offset-xs5>
              <v-select
                :items="feeEstimates"
                v-model="selectedFee"
                single-line
                item-text="name"
                item-value="value"
                label="fee..."
              >
              </v-select>
            </v-flex>
            <v-btn class="ma-3" @click="save" color="success" :loading="paymentInProgress" :disabled="!canSave()">
              Pay
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </ba-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import _ from 'lodash';

export default {
  props: ['selected'],
  data() {
    return {
      isLoading: false,
      wallets: [],
      price: undefined,
      selectedWalletCoin: 'BTC',
      dialog: false,
      selectedFee: 0,
      paymentInProgress: false,
      headers: [
        {
          text: 'Vendor',
          align: 'left',
          value: 'vendor',
        },
        {
          text: 'Amount',
          align: 'right',
          value: 'amount',
        },
        {
          text: 'Pay To',
          align: 'center',
          value: 'payfrom',
        },
        {
          text: 'Pay From',
          align: 'center',
          value: 'payto',
        },
      ],
    };
  },
  computed: {
    hasSelection() {
      return this.selected.length;
    },
    btnText() {
      return 'Pay ' + this.selected.length + ' Bills';
    },
  },
  apollo: {
    feeEstimates: {
      query: gql`
        query feeEstimates($coin: Coins!) {
          feeEstimates(coin: $coin) {
            id
            name
            coin
            value
          }
        }
      `,
      pollInterval: 60000,
      fetchPolicy: 'no-cache',
      loadingKey: 'isLoading',
      variables() {
        return {
          coin: 'BTC',
        };
      },
    },
    wallets: {
      query: gql`
        query wallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            type
            balance {
              totalFiatValue {
                displayValue
                currency
              }
            }
            enabledCoins
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      update(data) {
        return data.wallets;
      },
      loadingKey: 'isLoading',
    },
    price: {
      query: gql`
        query GetPrice($orgId: ID!, $coin: Coins!) {
          price(orgId: $orgId, coin: $coin) {
            price
          }
        }
      `,
      update(data) {
        return data.price.price;
      },
      variables() {
        return {
          coin: 'BTC',
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      error(err) {
        console.error('error getting price: ', err);
      },
    },
  },
  methods: {
    canSave() {
      if (this.selectedFee <= 1) {
        return false;
      }
      for (const i of this.selected) {
        if (
          !(i.selectedCoin && i.selectedCoin.trim() !== '' && i.selectedWalletId && i.selectedWalletId.trim() !== '')
        ) {
          return false;
        }
      }
      return true;
    },
    cleanMeta(apolloObj) {
      return _.omit(apolloObj, '__typename');
    },
    vendorAddressList(addresses) {
      const addr = [];
      for (const k of Object.keys(this.cleanMeta(addresses))) {
        if (addresses[k] && addresses[k].trim() !== '') {
          addr.push({ text: k, value: k });
        }
      }
      return addr;
    },
    getPrice(item) {
      const p = item.amount / this.price;
      return p.toPrecision(5);
    },
    closeDialog() {
      this.$emit('refresh');
      this.dialog = false;
    },
    enumerateVendorAddressesCoins(addresses) {
      return _.keys(this.cleanMeta(addresses)).join(', ');
    },
    filterEmptyAddresses(addresses) {
      const addr = {};
      for (const k in this.cleanMeta(addresses)) {
        if (addresses[k] && addresses[k].trim().length > 0) {
          addr[k] = addresses[k];
        }
      }
      return addr;
    },
    filteredWallets(vendorAddresses) {
      // only enterprise wallets (2) that support $coins
      const vendorSupportedCoins = _.keys(this.filterEmptyAddresses(vendorAddresses));
      return _.filter(this.wallets, (w) => {
        const walletSupportedCoins = _.values(this.cleanMeta(w.enabledCoins));
        const hasIntersect = _.intersection(walletSupportedCoins, vendorSupportedCoins).length > 0;
        // console.log(w.type, vendorSupportedCoins, walletSupportedCoins);
        return w.type === 2 && hasIntersect;
      });
    },
    save() {
      this.paymentInProgress = true;
      const bills = _.map(this.selected, (bill) => {
        return {
          id: bill.id,
          coin: bill.selectedCoin,
          walletId: bill.selectedWalletId,
        };
      });
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        fee: this.selectedFee,
        bills: bills,
      };

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $bills: [BillInput]!, $fee: Float!) {
              payBills(orgId: $orgId, bills: $bills, fee: $fee)
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(() => {
          this.$emit('show-snackbar', this.$t('_billPaid'), 'success');
        })
        .catch((err) => {
          this.$emit('show-snackbar', err.message, 'error');
          console.error(err);
        })
        .finally(() => {
          this.$emit('refresh');
          this.dialog = false;
          this.selectedFee = 0;
          this.paymentInProgress = false;
        });
    },
  },
};
</script>
